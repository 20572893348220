<template>
    <div>
        <!-- Title -->
        <p v-if="$can('READ', 'report-mitra-attendance')" class="uk-text-bold uk-text-lead">Mitra Attendance</p>
        <!-- End title -->

        <!-- Filter -->
        <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-flex uk-child-width-expand@s" style="gap: 15px">
            <form @change="filterDailyRecapWorkerAttendanceByCity">
                <select 
                    name="city" 
                    id="city" 
                    class="uk-select uk-border-rounded"
                    v-model="meta.domicile_city_id"
                >
                    <option 
                        :value="null" 
                        selected
                    >All City</option>
                    <option
                        v-for="(city, index) in cities"
                        :key="index" 
                        :value="city.domicile_city_id._id" 
                    >
                        {{ city.domicile_city_id ? city.domicile_city_id.name ? city.domicile_city_id.name : '-' : '-' }}
                    </option>
                </select>
            </form>
            <form @change="filterDailyRecapWorkerAttendanceByWarehouse">
                <select 
                    name="office" 
                    id="office" 
                    class="uk-select uk-border-rounded"
                    v-model="meta.company_office_id"
                >
                    <option 
                        :value="null" 
                        selected
                    >All Warehouse</option>
                    <option 
                        v-for="(office, index) in offices"
                        :key="index" 
                        :value="office.company_office_id._id" 
                    >
                        {{ office.company_office_id ? office.company_office_id.name ? office.company_office_id.name : '-' : '-' }}
                    </option>
                </select>
            </form>
            <form @change="filterDailyRecapWorkerAttendanceByShift">
                <select 
                    name="shift" 
                    id="shift" 
                    class="uk-select uk-border-rounded"
                    v-model="meta.company_shift_id"
                >
                    <option 
                        :value="null" 
                        selected
                    >All Shift</option>
                    <option
                        v-for="(shift, index) in shifts"
                        :key="index"  
                        :value="shift._id" 
                    >
                        {{ shift.name || '-' }}
                    </option>
                </select>
            </form>
            <datetime
                id="date"
                name="date"
                input-class="uk-input uk-border-rounded"
                type="date"
                value-zone="Asia/Jakarta"
                zone="Asia/Jakarta"
                format="dd LLL yyyy"
                :max-datetime="datetime.now().toISODate()"
                v-model="meta.date"
                @input="filterDailyRecapWorkerAttendanceByDate"
            ></datetime>
        </div>
        <!-- End filter -->

        <!-- Recap per query -->
        <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-margin-top uk-flex uk-flex-between uk-child-width-1-6 uk-text-center" style="gap: 15px;">
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid black;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead">{{ totalOffice }}</p>
                <p class="uk-margin-remove uk-padding-remove">Total</p>
                <p class="uk-margin-remove uk-padding-remove">Warehouse</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid black;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead">{{ totalWorker }}</p>
                <p class="uk-margin-remove uk-padding-remove">Total</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #FF0000;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #FF0000;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #FF0000;">{{ totalWorkerNotAbsent }}</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
                <p class="uk-margin-remove uk-padding-remove">Belum Absen</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #1DCD00;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #1DCD00;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #1DCD00;">{{ totalWorkerCheckIn }}</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
                <p class="uk-margin-remove uk-padding-remove">Absen Masuk</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #1DCD00;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #1DCD00;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #1DCD00;">{{ totalWorkerCheckOut }}</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
                <p class="uk-margin-remove uk-padding-remove">Absen Pulang</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #AB47BC;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #AB47BC;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #AB47BC;">{{ totalWorkerOvertime }}</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
                <p class="uk-margin-remove uk-padding-remove">Lembur</p>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-flex-column uk-border-rounded uk-padding-small" style="border: 2px solid #FFC737;">
                <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle">
                    <p class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" uk-spinner="ratio: 0.8" style="color: #FFC737;"></p>
                </div>
                <p v-else class="uk-margin-remove uk-padding-remove uk-text-bold uk-text-lead" style="color: #FFC737;">{{ totalWorkerLeave }}</p>
                <p class="uk-margin-remove uk-padding-remove">Mitra</p>
                <p class="uk-margin-remove uk-padding-remove">Izin</p>
            </div>
        </div>
        <!-- End recap per query -->

        <!-- Download report -->
        <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-margin-top">
            <button
                v-if="downloadLoading"
                type="button"
                class="uk-button uk-button-default"
                style="border-radius: 5px; width: 221px;"
                disabled
            >
                <span uk-spinner="ratio: 0.5"></span>
                <span class="uk-margin-small-left">Loading</span>
            </button>
            <button
                v-else
                type="button"
                class="uk-button uk-button-default"
                style="
                    border-radius: 5px;
                    width: 221px;
                    border: 1px solid #0ABAB5;
                    color: #FFFFFF;
                    background-color: #0ABAB5;
                "
                @click="downloadReport"
            >Download Report</button>
        </div>
        <!-- End download report -->

        <!-- Recap list -->
        <div v-if="$can('READ', 'report-mitra-attendance')" class="uk-margin-top uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                    <thead>
                        <tr>
                            <th class="uk-text-nowrap">Date</th>
                            <th class="uk-text-nowrap">Shift</th>
                            <th class="uk-text-center uk-text-nowrap">Total Mitra</th>
                            <th class="uk-text-center uk-text-nowrap">Belum Absen</th>
                            <th class="uk-text-center uk-text-nowrap">Absen Masuk</th>
                            <th class="uk-text-center uk-text-nowrap">Absen Pulang</th>
                            <th class="uk-text-center uk-text-nowrap">Lembur</th>
                            <th class="uk-text-center uk-text-nowrap">Mitra Izin</th>
                            <th class="uk-text-nowrap">City</th>
                            <th class="uk-text-nowrap">Warehouse</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="9" />
                    <template v-else>
                        <tbody v-if="recapList.docs.length > 0">
                            <tr v-for="(recap, index) in recapList.docs" :key="index">
                                <td class="uk-text-nowrap">{{ formatDate(recap.date) }}</td>
                                <td class="uk-text-nowrap">{{ recap.shift || '-' }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap">{{ recap.total_worker || 0 }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap" style="color: #FF0000;">{{ recap.total_worker_not_absent || 0 }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap" style="color: #1DCD00;">{{ recap.total_worker_checkin || 0 }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap" style="color: #1DCD00;">{{ recap.total_worker_checkout || 0 }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap" style="color: #AB47BC;">{{ recap.total_worker_overtime || 0 }}</td>
                                <td class="uk-text-bold uk-text-center uk-text-nowrap" style="color: #FFC737;">{{ recap.total_worker_leave || 0 }}</td>
                                <td class="uk-text-nowrap">{{ recap.city || '-' }}</td>
                                <td class="uk-text-nowrap">{{ recap.warehouse || '-' }}</td>
                            </tr>
                        </tbody>
                        <empty-table v-else :colspan="9" />
                    </template>
                </table>
            </div>
            <pagination
                :total-data="recapList.totalDocs"
                :limit-page="meta.limit"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End recap list -->

        <!-- Not authorized for read data this page -->
        <div v-else class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
            <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                <span class="uk-text-large uk-text-bold">
                    <p>You are not authorized for read data this page</p>
                </span>
            </div>
        </div>
        <!-- End not authorized for read data this page -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { notificationDanger } from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import formatter from "@/utils/formatter";

export default {
    name: 'MitraAttendanceTab',
    components: {
        Datetime,
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoadingTable: () => import('@/components/globals/tables/Loading')
    },
    props: {
        shiftListNoPaginate: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            datetime: DateTime,
            isLoading: false,
            downloadLoading: false,
            recapPerQuery: null,
            recapList: {
                docs: [],
                totalDocs: 0
            },
            cities: [],
            offices: [],
            shifts: [],
            meta: {
                limit: 10,
                page: 1,
                domicile_city_id: null,
                company_office_id: null,
                company_shift_id: null,
                date: DateTime.now().toFormat('yyyy-MM-dd')
            }
        };
    },
    watch: {
        async meta() {
            try {
                this.isLoading = true;
                this.resetRecap();
                await Promise.all([
                    this.setRecapPerQuery(),
                    this.setRecapList()
                ]);
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        totalOffice() {
            return this.offices.length;
        },
        totalWorker() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker ? this.recapPerQuery.total_worker : 0 : 0;
        },
        totalWorkerNotAbsent() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker_not_absent ? this.recapPerQuery.total_worker_not_absent : 0 : 0;
        },
        totalWorkerCheckIn() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker_checkin ? this.recapPerQuery.total_worker_checkin : 0 : 0;
        },
        totalWorkerCheckOut() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker_checkout ? this.recapPerQuery.total_worker_checkout : 0 : 0;
        },
        totalWorkerLeave() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker_leave ? this.recapPerQuery.total_worker_leave : 0 : 0;
        },
        totalWorkerOvertime() {
            return this.recapPerQuery ? this.recapPerQuery.total_worker_overtime ? this.recapPerQuery.total_worker_overtime : 0 : 0;
        },
        cityName() {
            let name = 'All_City';

            if ((this.meta.domicile_city_id) && (this.shiftListNoPaginate && this.shiftListNoPaginate.length > 0)) {
                const findCity = this.shiftListNoPaginate.find((shift) => {
                    if (
                        (shift && shift.domicile_city_id && shift.domicile_city_id._id) &&
                        (shift.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return shift;
                    }
                });

                if (findCity && findCity.domicile_city_id && findCity.domicile_city_id.name) {
                    name = findCity.domicile_city_id.name;
                }
            }

            return name;
        },
        officeName() {
            let name = 'All_Office';

            if ((this.meta.company_office_id) && (this.shiftListNoPaginate && this.shiftListNoPaginate.length > 0)) {
                const findCity = this.shiftListNoPaginate.find((shift) => {
                    if (
                        (shift && shift.company_office_id && shift.company_office_id._id) &&
                        (shift.company_office_id._id === this.meta.company_office_id)
                    ) {
                        return shift;
                    }
                });

                if (findCity && findCity.company_office_id && findCity.company_office_id.name) {
                    name = findCity.company_office_id.name;
                }
            }

            return name;
        }
    },
    mounted() {
        this.setCities();
        this.setOffices();
        this.setShifts();
    },
    methods: {
        ...mapActions({
            getDailyRecapWorkerAttendance: 'daily_recap_worker_attendance/getDailyRecapWorkerAttendance',
            getDailyRecapWorkerAttendanceList: 'report/getDailyRecapWorkerAttendanceList',
            getDailyRecapPerQuery: 'daily_recap_worker_attendance/getDailyRecapPerQuery'
        }),
        async setRecapPerQuery() {
            try {
                const meta = {
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    company_shift_id: this.meta.company_shift_id,
                    date: this.meta.date
                };
                const response = await this.getDailyRecapPerQuery(meta);
                if (response) {
                    this.recapPerQuery = response;
                }
            } catch (error) {   
                notificationDanger(error);
            }
        },
        async setRecapList() {
            try {
                const response = await this.getDailyRecapWorkerAttendanceList(this.meta);
                if (response && response.docs && response.totalDocs) {
                    this.recapList.docs = response.docs;
                    this.recapList.totalDocs = response.totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterDailyRecapWorkerAttendanceByCity() {
            try {
                this.isLoading = true;
                this.meta.company_office_id = null;
                this.meta.company_shift_id = null;
                this.filterOffice();
                this.filterShift();
                this.resetRecap();
                const meta = {
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    company_shift_id: this.meta.company_shift_id,
                    date: this.datetime.fromISO(this.meta.date).toFormat('yyyy-MM-dd')
                };
                const response = await this.getDailyRecapWorkerAttendance(meta);
                if (response && response.status === 'OK') {
                    await Promise.all([
                        this.setRecapPerQuery(),
                        this.setRecapList()
                    ]);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async filterDailyRecapWorkerAttendanceByWarehouse() {
            try {
                this.isLoading = true;
                this.meta.company_shift_id = null;
                this.filterShift();
                this.resetRecap();
                const meta = {
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    company_shift_id: this.meta.company_shift_id,
                    date: this.datetime.fromISO(this.meta.date).toFormat('yyyy-MM-dd')
                };
                const response = await this.getDailyRecapWorkerAttendance(meta);
                if (response && response.status === 'OK') {
                    await Promise.all([
                        this.setRecapPerQuery(),
                        this.setRecapList()
                    ]);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async filterDailyRecapWorkerAttendanceByShift() {
            try {
                this.isLoading = true;
                this.resetRecap();
                const meta = {
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    company_shift_id: this.meta.company_shift_id,
                    date: this.datetime.fromISO(this.meta.date).toFormat('yyyy-MM-dd')
                };
                const response = await this.getDailyRecapWorkerAttendance(meta);
                if (response && response.status === 'OK') {
                    await Promise.all([
                        this.setRecapPerQuery(),
                        this.setRecapList()
                    ]);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async filterDailyRecapWorkerAttendanceByDate() {
            try {
                this.isLoading = true;
                this.resetRecap();
                const meta = {
                    domicile_city_id: this.meta.domicile_city_id,
                    company_office_id: this.meta.company_office_id,
                    company_shift_id: this.meta.company_shift_id,
                    date: this.datetime.fromISO(this.meta.date).toFormat('yyyy-MM-dd')
                };
                const response = await this.getDailyRecapWorkerAttendance(meta);
                if (response && response.status === 'OK') {
                    await Promise.all([
                        this.setRecapPerQuery(),
                        this.setRecapList()
                    ]);
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoading = false;
            }
        },
        async downloadReport() {
            try {
                this.downloadLoading = true;
                const city = this.cityName;
                const office = this.officeName;
                const attendanceReport = await this.getDailyRecapWorkerAttendanceList(this.meta);
                const dataExcel = [];
                attendanceReport.docs.forEach(data => {
                    let obj = {
                        "City": data.city,
                        "Warehouse": data.warehouse,
                        "Date": data.date,
                        "Shift": data.shift,
                        "Total Mitra": data.total_worker,
                        "Belum Absen": data.total_worker_not_absent,
                        "Absen Masuk": data.total_worker_checkin,
                        "Absen Pulang": data.total_worker_checkout,
                        "Izin": data.total_worker_leave,
                    };
                    dataExcel.push(obj);
                });
                excelDownloader(dataExcel, `Rangkuman_Absen_${ city }_${ office }.xls`);
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadLoading = false;
            }
        },
        resetRecap() {
            this.recapPerQuery = null;
            this.recapList.docs = [];
            this.recapList.totalDocs = 0;
        },
        setCities() {
            if (this.shiftListNoPaginate && this.shiftListNoPaginate.length > 0) {
                this.shiftListNoPaginate.forEach((shift) => {
                    const findCity = this.cities.find((data) => {
                        if (
                            (shift.domicile_city_id && shift.domicile_city_id._id) && 
                            (data.domicile_city_id._id === shift.domicile_city_id._id)
                        ) {
                            return data;
                        }
                    });

                    if (!findCity) {
                        this.cities.push(shift);
                    }
                });
            }

            if (this.cities.length > 0) {
                this.cities.sort((a, b) => {
                    return (a.domicile_city_id.name.toUpperCase() > b.domicile_city_id.name.toUpperCase()) ? 
                        1 : ((b.domicile_city_id.name.toUpperCase() > a.domicile_city_id.name.toUpperCase()) ? -1 : 0);
                });
            }
        },
        setOffices() {
            if (this.shiftListNoPaginate && this.shiftListNoPaginate.length > 0) {
                this.shiftListNoPaginate.forEach((shift) => {
                    const findOffice = this.offices.find((data) => {
                        if (
                            (shift.company_office_id && shift.company_office_id._id) && 
                            (data.company_office_id._id === shift.company_office_id._id)
                        ) {
                            return data;
                        }
                    });

                    if (!findOffice) {
                        this.offices.push(shift);
                    }
                });
            }

            if (this.offices.length > 0) {
                this.offices.sort((a, b) => {
                    return (a.company_office_id.name.toUpperCase() > b.company_office_id.name.toUpperCase()) ? 
                        1 : ((b.company_office_id.name.toUpperCase() > a.company_office_id.name.toUpperCase()) ? -1 : 0);
                });
            }
        },
        setShifts() {
            if (this.shiftListNoPaginate && this.shiftListNoPaginate.length > 0) {
                this.shiftListNoPaginate.forEach((shift) => {
                    const findShift = this.shifts.find((data) => {
                        if (
                            (shift && shift._id) && 
                            (data._id === shift._id)
                        ) {
                            return data;
                        }
                    });

                    if (!findShift) {
                        this.shifts.push(shift);
                    }
                });
            }

            if (this.shifts.length > 0) {
                this.shifts.sort((a, b) => {
                    return (a.name.toUpperCase() > b.name.toUpperCase()) ? 
                        1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);
                });
            }
        },
        filterOffice() {
            this.setOffices();
            if (this.meta.domicile_city_id) {
                const filterOffice = this.offices.filter((data) => {
                    if (
                        (data.domicile_city_id && data.domicile_city_id._id) &&
                        (data.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return data;
                    }
                });

                this.offices = filterOffice;
            }

            this.offices.sort((a, b) => {
                return (a.company_office_id.name.toUpperCase() > b.company_office_id.name.toUpperCase()) ? 
                    1 : ((b.company_office_id.name.toUpperCase() > a.company_office_id.name.toUpperCase()) ? -1 : 0);
            });
        },
        filterShift() {
            this.setShifts();
            if (this.meta.domicile_city_id) {
                const filterShift = this.shifts.filter((data) => {
                    if (
                        (data.domicile_city_id && data.domicile_city_id._id) &&
                        (data.domicile_city_id._id === this.meta.domicile_city_id)
                    ) {
                        return data;
                    }
                });
                this.shifts = filterShift;
            } 
            
            if (this.meta.company_office_id) {
                const filterShift = this.shifts.filter((data) => {
                    if (
                        (data.company_office_id && data.company_office_id._id) &&
                        (data.company_office_id._id === this.meta.company_office_id)
                    ) {
                        return data;
                    }
                });
                this.shifts = filterShift;
            }

            if (this.shifts.length > 0) {
                this.shifts.sort((a, b) => {
                    return (a.name.toUpperCase() > b.name.toUpperCase()) ? 
                        1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);
                });
            }
        },
        formatDate(data) {
            if (data === null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }   
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}

th {
    color: #ffffff;
}

.myrobin-background-primary {
    background: #0ABAB5;
}

.myrobin-color-white {
    color: #FFFFFF;
}

.myrobin-color-grey {
    color: #9A9A9A;
}
</style>
